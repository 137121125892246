<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      @hidden="resetVariables()"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            {{ eventLocal.id ? $t('message.update') : $t('message.add') }}
            {{
              eventLocal.extendedProps.isInstance && eventLocal.title === 'Seguimiento'
                ? `${$t('message.follow_up')} IA`
                : isInstance || eventLocal.extendedProps.isInstance
                  ? $t('metadata.instance')
                  : eventLocal.extendedProps.isBehaviourEvaluation
                    ? $t('label.behaviour_evaluation')
                    : $t('label.Event')
            }}
          </h5>
          <div>
            <!-- <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event');
                hide();
              "
            /> -->
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Metadata -->
            <div v-if="eventLocal.extendedProps.metadata && eventLocal.extendedProps.metadata.length">
              <b-form-group
              v-for="metadata in eventLocal.extendedProps.metadata" :key="metadata._id"
              :label="$t(`metadata.${metadata.name}`)"
              :label-for="$t(`metadata.${metadata.name}`)"
              description=""
              >
                <validation-provider
                  :name="$t(`metadata.${metadata.name}`)"
                  :rules="metadata.name === 'instance' ? 'required' : null"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-if="metadata.type === 'select'"
                    v-model="metadata.answer"
                    :multiple="false"
                    :options="optionsMetadata[metadata.name]"
                    :placeholder="$t(`placeholder.${metadata.name}`)"
                  />
                  <small
                    v-if="metadata.name === 'instance' && metadata.answer && !optionsMetadata[metadata.name].find(e => e === metadata.answer)"
                    style="margin-top: 3px"
                    class="d-block text-danger"
                  >
                    {{ changeInstanceMessage }}
                  </small>
                  
                  <b-form-input
                    v-if="metadata.type === 'input'"
                    v-model="metadata.answer"
                    :placeholder="$t(`placeholder.${metadata.name}`)"
                  />
  
                  <flat-pickr
                    v-if="metadata.type === 'datepicker'"
                    v-model="metadata.answer"
                    class="form-control"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                 </validation-provider>
                </b-form-group>
            </div>

            <!-- Participants -->  
            <b-form-group
              v-if="isInstance || eventLocal.extendedProps.isInstance"
              :label="$t('label.participants')" label-for="label-participants">
                <v-select
                  v-model="eventLocal.extendedProps.participants"
                  :placeholder="$t('placeholder.participants')"
                  :multiple="true"
                  :options="eventLocal.id ? workersTotal : workersSupervised"
                  label="title"
                  :reduce="(process) => process.value"
                />
            </b-form-group>

            <!-- Title -->
            <validation-provider
              v-else
              #default="validationContext"
              :name="$t('label.Title')"
              rules="required"
            >
              <b-form-group :label="$t('label.Title')" label-for="event-title">
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('metadata.instance')"
                  :disabled="!isInstance && !eventLocal.extendedProps.isInstance"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Fields that apply only for events (not for instances or behaviour evaluations) -->
            <div v-if="!isInstance && !eventLocal.extendedProps.isInstance && !eventLocal.extendedProps.isBehaviourEvaluation">
              <!-- Process -->
              <b-form-group :label="$t('label.process')" label-for="event-title">
                <v-select
                  v-model="eventLocal.extendedProps.process._id"
                  :options="processes"
                  label="title"
                  :reduce="(process) => process.value"
                  :disabled="eventLocal.confirmation"
                />
              </b-form-group>
              
              <!--b-form-group label="Evaluado" label-for="event-title">
                <v-select
                  v-model="eventLocal.attendee"
                  :options="workers"
                  :label="$t('label.title')"
                  :reduce="(worker) => worker._id"
                />
              </b-form-group-->
  
              <!-- Start confirmation -->
              <div class="d-flex mt-2">
                <b-button
                  v-if="eventLocal.id"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  @click="
                    $router.push({
                      path: eventLocal.extendedProps.confirmation
                        ? `/habit/confirmation/${eventLocal.extendedProps.confirmation.pending ? 'edit' : 'view'}/${eventLocal.extendedProps.confirmation._id}`
                        : `/habit/confirmation/new/${eventLocal.extendedProps.process._id}/${eventLocal.extendedProps.attendee && eventLocal.extendedProps.attendee._id || 'worker'}/${eventLocal.id}`
                    })
                  "
                >
                  {{ eventLocal.extendedProps.confirmation
                    ? eventLocal.extendedProps.confirmation.pending
                      ? $t('message.continue_confirmation')
                      : $t('message.view_confirmation')
                    : $t('message.start_confirmation')
                  }}
                </b-button>
              </div>
  
              <!-- Calendar -->
              <validation-provider
                #default="validationContext"
                :name="$t('Calendar')"
              >
                <b-form-group
                  :label="$t('Calendar')"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.calendar"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="calendarOptions"
                    label="label"
                    :reduce="(calendar) => calendar.label"
                    input-id="calendar"
                    :disabled="true"
                  >
                    <template #option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height: 10px; width: 10px"
                      />
                      <span> {{ $t(label) }}</span>
                    </template>
  
                    <template #selected-option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height: 10px; width: 10px"
                      />
                      <span> {{ $t(label) }}</span>
                    </template>
                  </v-select>
  
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <!-- Evaluate behaviour -->
            <div v-if="eventLocal.extendedProps.isBehaviourEvaluation" class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-2"
                @click="
                  $router.push({
                    path: eventLocal.extendedProps.commitment.completed
                      ? `/habit/commitment/view/${eventLocal.extendedProps.commitment._id}`
                      : `/habit/commitment/new/${eventLocal.extendedProps.commitment._id}/${eventLocal.extendedProps.attendee._id}/${eventLocal.id}`
                  })
                "
              >
                {{ eventLocal.extendedProps.commitment.completed
                  ? $t('message.view_evaluation')
                  : $t('message.evaluate_behaviour')
                }}
              </b-button>
            </div>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.start_date')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.start_date')"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="datePickerConfig"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              v-if="!eventLocal.extendedProps.isBehaviourEvaluation"
              #default="validationContext"
              :name="$t('label.end_date')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.end_date')"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="datePickerConfig"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <!--b-form-group>
              <b-form-checkbox
                v-model="eventLocal.allDay"
                name="check-button"
                switch
                inline
              >
                All Day
              </b-form-checkbox>
            </b-form-group-->

            <!-- Event URL -->
            <!--validation-provider
              #default="validationContext"
              name="Event URL"
              rules="url"
            >

              <b-form-group
                label="Event URL"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.eventUrl"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="htttps://www.google.com"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider-->

            <!-- Guests -->
            <!--b-form-group label="Add Guests" label-for="add-guests">
              <v-select
                v-model="eventLocal.extendedProps.guests"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="guestsOptions"
                label="name"
                input-id="add-guests"
              >
                <template #option="{ avatar, name }">
                  <b-avatar size="sm" :src="avatar" />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group-->

            <!-- Location -->
            <!--b-form-group label="Location" label-for="event-location">
              <b-form-input
                id="event-location"
                v-model="eventLocal.extendedProps.location"
                trim
                placeholder="Event Location"
              />
            </b-form-group-->

            <!-- Textarea -->
            <!--b-form-group label="Description" label-for="event-description">
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.extendedProps.description"
              />
            </b-form-group-->

            <!-- Assistance -->
            <b-form-group
              class="my-3"
              v-if="assistanceIAFunctionality && eventLocal.extendedProps.assistance"
              label-for="assistance"
            >
              <b-button
                type="button"
                variant="blackbtn btn-dark"
                @click="handleAssistance(eventLocal.extendedProps.assistance)"
              >
                {{ $t('View') }} {{ $t('Assistance') }} IA
              </b-button>
              <img src="@/assets/images/avatars/iconoChatbot.png" alt="chatbot" class="chatbot">
            </b-form-group>

            <!-- Add improvement -->
            <b-form-group
              v-if="commitmentFunctionality && !eventLocal.extendedProps.isBehaviourEvaluation"
              :label="$t('label.commitments')"
              label-for="task-commitments"
            >
              <ul class="list-group mb-1">
                <li
                  v-for="(improvement, index) in eventLocal.extendedProps.improvements"
                  :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center pt-0 pb-0"
                >
                  <div>
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${setImprovementColor(improvement.dueDate, improvement.completed)}`"
                      style="height: 10px; width: 10px"
                    />
                    <span>{{ improvement.note.length > 30 ? improvement.note.slice(0, 30) + "..." : improvement.note }}</span>
                  </div>
                  <div>
                    <EditButton @clicked="handleImprovementEdit(improvement, index)"/>
                    <DeleteButton v-if="isOnline" v-b-modal="'improvement list' + index"/>
                    <b-modal
                      v-if="isOnline"
                      :id="'improvement list' + index"
                      centered
                      no-close-on-backdrop
                      modal-class="modal-danger"
                      ok-variant="danger"
                      cancel-variant="outline-secondary"
                      :title="$t('message.confirm_action')"
                      :ok-title="$t('message.delete')"
                      :cancel-title="$t('message.cancel')"
                      @ok="removeImprovement(improvement, index)"
                    >
                      {{ $t('message.confirm_delete_commitment') }}
                    </b-modal>
                  </div>
                </li>
              </ul>
              <b-button
                v-if="isOnline"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                type="button"
                @click="openImprovementHandler"
              >
                {{ $t('add_commitment') }}
              </b-button>
            </b-form-group>

            <hr>

            <!-- Form Actions -->
            <div v-if="isOnline" class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? $t('message.update') : $t('message.add') }}
              </b-button>
              <!--b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-2"
                variant="outline-secondary"
              >
                {{ $t('message.reset') }}
              </b-button-->
              <b-button
                v-if="eventLocal.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-danger"
                v-b-modal="'modal-event-handler'"
              >
                {{ $t('message.delete') }}
              </b-button>
              <b-modal
                id="modal-event-handler"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                :ok-disabled="!deletedJustification"
                @hidden="resetModal"
                @ok="removeEvent"
              >
                {{ $t('message.confirm_delete_event') }}
                <div class="pt-2">
                  <label>
                    {{ $t('label.Justification') }}
                  </label>
                  <b-form-input v-model="deletedJustification" />
                  <small v-if="!deletedJustification" class="text-danger">
                    {{ $t('message.justification_required') }}
                  </small>
                </div>
              </b-modal>
            </div>
          </b-form>
        </validation-observer>

        <!-- Improvement Sidebar -->
        <todo-task-handler-sidebar
          v-model="isImprovementHandlerSidebarActive"
          :task="improvement"
          :taskIndex="improvementIndex"
          :clear-task-data="clearImprovementData"
          :domains="domains"
          :optionsMetadata="optionsMetadata"
          handlerId="improvement"
          @add-task="addImprovement"
          @update-task="updateImprovement"
          @remove-task="removeImprovement"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BModal, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs, onMounted, computed, watch } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import useCommon from "@/views/organization/useCommon";
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import store from '@/store'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BModal,
    EditButton,
    DeleteButton,
    TodoTaskHandlerSidebar,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    isOnline: {
      type: Boolean,
      required: true,
    },
    isInstance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const {
      getWorkersSupervisedForDropDown,
      workersSupervised,
      getWorkersTotalForDropDown,
      workersTotal,
      getProcessesForDropDown,
      processes,
      getMetadataForDropDown,
      metadataNotMapped,
      handleAssistance
    } = useCommon();

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const assistanceIAFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').assistanceIA_functionality
    const clearFormData = ref(null);
    const datePickerConfig = ref({ enableTime: true, dateFormat: 'Y-m-d H:i' })
    const deletedJustification = ref("")
    const isImprovementHandlerSidebarActive = ref(false)
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const { getDomains } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))
    const now = new Date()
    now.setHours(12, 0, 0, 0)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
      improvementsToUpdate,
      improvementsToDelete,
      optionsMetadata,
      changeInstanceMessage,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    const removeEvent = () => {
      emit("remove-event", {
        eventData: eventLocal.value,
        deletedJustification: deletedJustification.value,
        improvementsToDelete: improvementsToDelete.value
      });
    }

    const resetModal = () => {
      deletedJustification.value = ""
    }

    const blankImprovement = {
      _id: null,
      client_id: clientId,
      note: '',
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      description: '',
      assignee: null,
      tags: [],
      completed: false,
      deleted: false,
      important: false,
      imgData: {}
    }
    const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))
    const improvementIndex = ref(-1)
    const clearImprovementData = () => {
      improvement.value = JSON.parse(JSON.stringify(blankImprovement))
      improvementIndex.value = -1
    }

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
        })
        blankImprovement.metadata = val
        if (!improvement.value._id) improvement.value = { ...improvement.value, metadata: val }
      }
    })

    const handleImprovementEdit = (improvementData, index) => {
      improvement.value = improvementData
      improvementIndex.value = index
      isImprovementHandlerSidebarActive.value = true
    }

    const addImprovement = improvementData => {
      eventLocal.value.extendedProps.improvements.push(improvementData)
    }

    const updateImprovement = (improvementData, index) => {
      eventLocal.value.extendedProps.improvements[index] = improvementData

      if (improvementData._id) {
        improvementsToUpdate.value.push(improvementData)
      }  
    }

    const removeImprovement = (improvementData, index) => {
      eventLocal.value.extendedProps.improvements.splice(index, 1)
      if (improvementData._id) {
        improvementData.deleted = true
        improvementsToDelete.value.push(improvementData)
      }
    }

    const resetVariables = () => {
      // Close Improvement Sidebar if open
      isImprovementHandlerSidebarActive.value = false
      
      // Empty improvementsToUpdate and improvementsToDelete arrays
      improvementsToUpdate.value = []
      improvementsToDelete.value = []
    }

    const setImprovementColor = (dueDate, isCompleted) => {
      if (isCompleted) return "primary"
      const dueDateFormatted = dueDate ? new Date(`${dueDate.slice(0, 10)} 12:00:00`) : null
      if (dueDateFormatted < now) return "danger"
      return "warning"
    }

    const openImprovementHandler = () => {
      const instance = eventLocal.value.extendedProps?.metadata?.find(e => e.name === 'instance').answer
      if(improvement.value.metadata) {
        improvement.value.metadata.forEach(e => {
          if(e.name === 'instance') e.answer = instance
        })
      }
      improvement.value = {...improvement.value}
      isImprovementHandlerSidebarActive.value  = true
    }

    onMounted(() => {
      getProcessesForDropDown()
      getMetadataForDropDown({ category: "improvement", option: "notMapped" })
      getWorkersSupervisedForDropDown()
      getWorkersTotalForDropDown()
    });

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,

      workersSupervised,
      workersTotal,
      processes,
      removeEvent,
      datePickerConfig,
      deletedJustification,
      resetModal,
      commitmentFunctionality,
      handleImprovementEdit,
      removeImprovement,
      isImprovementHandlerSidebarActive,
      improvement,
      improvementIndex,
      clearImprovementData,
      addImprovement,
      updateImprovement,
      resetVariables,
      domains,
      optionsMetadata,
      setImprovementColor,
      openImprovementHandler,
      changeInstanceMessage,
      handleAssistance,
      assistanceIAFunctionality
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>

.chatbot{
  width: 3rem; 
  height: auto;
  padding-left: 0.5rem;
}

</style>